import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/">
                Home
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services">
                Services
            </HashLink>
            {/* <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/">
                Portfolio
            </HashLink> */}
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/#about">
                About Us
            </HashLink>
            
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSdO24QJ4pt95rZCtTp2I4ZCHMYXwnhM2kt8nNOVNBUc1Mjf8g/viewform' className="px-4 font-extrabold text-gray-500 hover:text-blue-900" >
                 Contact Us
                </a>
          
            <div className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"   >
                <a href='https://docs.google.com/forms/d/e/1FAIpQLScXQ3Sw93zXIaLWjVBSm8LUZwvMdPskOzVgexkySMIsIXI6Bw/viewform'  >
                  ቆጾሮ ሓዝ
                </a>
                
            </div>
        </>
    )
}

export default NavLinks;
